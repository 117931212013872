import { useEffect, useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import createOrganizationData  from '../../../dummyData/CreateOrganization.json';

export const useOranisationsFunc = () => {
    const [ isOpen, setIsOpen ]=useState(false);
    const [ createNewOrganization, setCreateNewOrganization ] = useState({
        name: '',
        description: ''
    })
    const [ error, setError ]=useState({
        name: '',
        description: ''
    })
    const[ isEdit, setIsEdit ]=useState('');
    const { callApi } = useCustomAxiosCall();
    const [ organisation, setOrganisation ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const handleCreateOrganizationModal = () => {
        setIsOpen(true);
    }
    const closeModal = () => {
        setCreateNewOrganization({
            name: '',
            description: ''
        })
        setError({
            name: '',
            description: ''
        })
        setIsEdit('')
        setIsOpen(false);
    };

    const handleChange=(e)=>{
        const target=e.target;
        setError({ ...error, [ target.name ]: '' });
        setCreateNewOrganization({ ...createNewOrganization, [ target.name ]: target.value })
    }
    const handleValidation=()=>{
        let formError = {};
        let isValidate=false;
        Object.keys(createNewOrganization).forEach((key) => {
            if (!createNewOrganization.name){
                formError[ key ] = `${ key.charAt(0).toUpperCase() + key.slice(1) } is required`;
                isValidate=true;
            }})
        setError({ ...error, ...formError })
        return isValidate;
    }
    const fetchData = async () => {
        setLoading(true);
        const response = await callApi({
            uriEndPoint: {
                url: '/organizations',
                method: 'GET',
            },
        });
        const result = response?.data;
        if (result?.status === 200) {
            setOrganisation(result?.data);
        } else if (result.statusCode === 403){
            setOrganisation([]);
        }
        setLoading(false)
    };
    const handleSubmit = async () => {
        const isValidate=handleValidation();
        if(!isValidate){
            if(isEdit){
                const response = await callApi({
                    uriEndPoint: {
                        url: `/organizations/${ isEdit }`,
                        method: 'PUT',
                    },
                    body: createNewOrganization
                });
                const result = response.data;
                if (result.status === 200) {
                    fetchData()
                } else if (result.statusCode === 403){
                    console.log(result)
                } else if (result.statusCode === 404){
                    console.log(result);
                }
                closeModal();
            }
            else{
                const response = await callApi({
                    uriEndPoint: {
                        url: '/organizations',
                        method: 'POST',
                    },
                    body: createNewOrganization
                });
                const result = response.data;
                if (result.status === 200) {
                    fetchData()
                } else if (result.statusCode === 403){
                    console.log(result);
                } else if (result.statusCode === 404){
                    console.log(result);
                }
                closeModal();
            }
        }
    }
    const handleDelete=async(id)=>{
        const response = await callApi({
            uriEndPoint: {
                url: `/organizations/${ id }`,
                method: 'DELETE',
            },
        });
        const result = response.data;
        if (result.status === 200) {
            fetchData()
        } else if (result.statusCode === 403){
            console.log(result)
        } else if (result.statusCode === 404){
            console.log(result);
        }
    }
    useEffect(()=>{
        if(isEdit && organisation?.length > 0){
            organisation?.forEach(org => {
                if(org?._id===isEdit)
                    setCreateNewOrganization({
                        name: org?.name,
                        description: org?.description
                    })
            });
        }
    },[ isEdit, isOpen ])

    useEffect(() => {
        fetchData();
    }, []);

    return { organisation, loading, error, createOrganizationData, handleSubmit, handleDelete, handleCreateOrganizationModal, handleChange, createNewOrganization, isOpen, setIsEdit, closeModal };
}
