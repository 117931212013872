import { useEffect, useState } from 'react';
import data from '../../../dummyData/ApiConnectors.json';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useLocation } from 'react-router-dom';
import { capitalizeCamelCaseWords } from '../../../helpers';
export const useConnectorCardIIFunc = ({
    onConnectionSuccess = () => { }
}) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ redirectUrl, setRedirectUrl ] = useState('');
    const [ apiConnectors, setApiConnectors ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);

    const [ error, setError ] = useState({})

    useEffect(() => {

        if (redirectUrl) {
            window.open(redirectUrl, '_blank');
        }
    }, [ redirectUrl ]);

    const [ connectorDetails, setConnectorDetals ] = useState({});

    const { callApi } = useCustomAxiosCall();

    const { state } = useLocation();

    const handleCreateConnectorModal = async (connector) => {
        setError({})
        if (connector.machineName === 'microsoft_dynamics_365') {
            setIsLoading(true)
            const response = await callApi({
                uriEndPoint: {
                    url: `/organization/${ state.organisation_id }/connector`,
                    method: 'POST'
                },
                body: {
                    name: connector.machineName,
                    ownerEmail: connector.ownerEmail,
                    tribe: connector.category
                }
            });
            if (response.status !== 201 && response.status !== 200){
                if (response.data.message === 'ALREADY-CONNECTED') {
                    setError({ 'redirect':'Already Connected' })
                }
                else {
                    setError({ 'redirect':'Something Went Wrong... Please try again later' })
                }
            }
            if (response?.data?.data?.redirect_uri) {
                setRedirectUrl(response?.data?.data?.redirect_uri);
            }
            setIsLoading(false);
        }
        else {
            let connectorDetail = data[ connector.machineName ] || data.apiConnector;
            let INIT_CONNECTOR_STATE = {};
            for (let field in connectorDetail) {
                if (connectorDetail[ field ].name) INIT_CONNECTOR_STATE[ connectorDetail[ field ].name ] = ''
            }
            setApiConnectors(INIT_CONNECTOR_STATE);
            setError(INIT_CONNECTOR_STATE);
            setIsOpen(true);
            setConnectorDetals(connector);
        }
    }

    const closeModal = () => {
        setIsOpen(false);
        setApiConnectors({})
        setError({})
    };

    const handleValidation = () => {
        let formError = {};
        let isValidated = true;
        Object.keys(apiConnectors).forEach((key) => {
            if (!apiConnectors[ key ]) {
                formError[ key ] = `${ capitalizeCamelCaseWords(key) } is required`;
                isValidated = false;
            }
        })
        setError(formError)
        return isValidated;
    }

    const handleChange = (e) => {
        setError({})
        const target = e.target;
        setApiConnectors({ ...apiConnectors, [ target.name ]: target.value })
    };

    const handleSubmit = async () => {
        const isValidated = handleValidation();
        if (!isValidated) {
            return;
        }
        setIsLoading(true);
        // Adding Required Fields
        const requestBody = {
            name: connectorDetails.machineName || '',
            storeName: apiConnectors.storeName || '',
            accessToken: apiConnectors.accessToken || '',
            clientSecret: apiConnectors.clientSecret || '',
            tribe: connectorDetails?.category || ''
        };
        // Adding additional fields
        for (let fieldName in apiConnectors) {
            if (fieldName !== 'storeName' && fieldName !== 'accessToken')
                requestBody[ fieldName ] = apiConnectors[ fieldName ]
        }
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ state.organisation_id }/connector`,
                method: 'POST'
            },
            body: requestBody
        });
        
        if ((connectorDetails.machineName === 'whiplash' || connectorDetails.machineName === 'tiktok') && response.data?.message === 'CONNECTOR-CREATED') {
            setRedirectUrl(response?.data?.data?.url)
            closeModal();
            return
        }
        if (response.data?.message === 'CONNECTOR-CREATED') {
            onConnectionSuccess();
            closeModal();
        } else {
            setError({ response: response?.data?.message || 'Something Went Wrong' })
            console.log('Error:', response);
        }
        setIsLoading(false)
    };

    return { handleSubmit, handleCreateConnectorModal, handleChange, isLoading, apiConnectors, isOpen, closeModal, data, error };

}