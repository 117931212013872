import { useState, useCallback } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';

const useConfigMapper = () => {
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState({});
    const [ selectedTribe, setSelectedTribe ] = useState('3pl')
    const { callApi } = useCustomAxiosCall();
    const getConfigData = useCallback(async (tribe) => {
        setSelectedTribe(tribe)
        setLoading(true);
        try {
            const response = await callApi({
                uriEndPoint: {
                    url: `/tribe-config/${ tribe }`,
                    method: 'GET',
                    service: 'config-mapper',
                },
            });

            if (response?.status === 200 && !response?.data?.error) {
                setData(response?.data?.data || {});
                return response?.data?.data;
            } else {
                console.error('Error fetching data:', response?.data?.error || 'Unknown error');
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            setLoading(false);
        }
    }, [ callApi ]);

    return { data, loading, getConfigData, selectedTribe };
};

export default useConfigMapper;
